import { useWidth } from "@/utils";
import { H1 } from "@blueprintjs/core";
import { CSSProperties } from "react";

const imgAspectRatio = 287 / 1024;

const styles: Record<string, CSSProperties> = {
  topImg: {
    position: "absolute",
    top: 0,
    left: "1rem",
    width: "calc(100% - 2rem)",
    clipPath: `polygon(100% 0%, 100% 100%, 100% 100%, 0% 0%, 0% 0%)`,
  },
};

interface Props {
  title: string;
}

const Header = ({ title }: Props) => {
  const { sm } = useWidth();

  return (
    <>
      <img
        style={{
          ...styles.topImg,
          left: sm ? 0 : "1rem",
          width: !sm ? "calc(100% - 2rem)" : "100%",
        }}
        src={require("@/assets/wavy-cropped.png")}
        alt="Wavy"
      />
      <H1
        style={{
          paddingTop: `calc((100% * ${imgAspectRatio}) - ${
            sm ? "24px" : "30px"
          })`,
          marginBottom: 15,
          zIndex: 1000
        }}
      >
        {title}
      </H1>
    </>
  );
};

export default Header;
