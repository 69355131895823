import React from "react";

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const FullContainer = ({ children, style }: Props) => {
  const container: React.CSSProperties = {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    overflowY: "scroll",
    ...style,
  };

  return <div style={container}>{children}</div>;
};

export default FullContainer;
