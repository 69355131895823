import { useMediaQuery } from "react-responsive";

export function useWidth() {
  const isXs = useMediaQuery({ query: '(max-width: 576px)' });
  const isSm = useMediaQuery({ query: '(max-width: 768px)' });
  const isMd = useMediaQuery({ query: '(max-width: 992px)' });
  const isLg = useMediaQuery({ query: '(min-width: 992px)' });

  return {
    xs: isXs,
    sm: isSm,
    md: isMd,
    lg: isLg
  }
}
