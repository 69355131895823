import { ResumePoint } from "@/types";

export const resume: Array<ResumePoint> = [
  {
    company: "ittybrief",
    location: "ittybrief.com",
    startDate: "Jan 2023",
    imgSrc:
      "https://ittybrief.blob.core.windows.net/static/robot-icon-padded.png",
    link: "https://www.ittybrief.com",
    title: "Founder",
    callouts: [
      "Building a personalized newsletter service that automatically curates sections with news and media relevant to the user's interests",
      "Offering advertisers the ability to target individual users based on their provided interests with natural language querying",
    ],
  },
  {
    company: "Landis",
    location: "New York, NY",
    startDate: "Jul 2022",
    imgSrc:
      "https://media.licdn.com/dms/image/C4E0BAQHIcZYGles1PA/company-logo_200_200/0/1602887280961?e=2147483647&v=beta&t=IsS-haK277yafMnos7YhDlzVg0OF-JiQs3rwA9dUHds",
    title: "Full-Stack Software Engineer",
    link: "https://landis.com",
    callouts: [
      "Led overhaul of document submission UX resulting in an extensible data structure, a better user upload experience, transparent document rejection reasons and a more effective internal review process",
      "Integrated Google Lending AI to asynchronously classify and extract uploaded documents at scale",
      "Built company metrics dashboard used by the executive team weekly and by the board quarterly",
    ],
  },
  {
    company: "locked.art",
    location: "locked.art",
    startDate: "Nov",
    endDate: "Dec 2022",
    imgSrc: "https://stickyart.blob.core.windows.net/static/dark.png",
    title: "Founder",
    link: "https://locked.art",
    callouts: [
      "Built workflow to allow users to create and purchase physical goods from text-based descriptions",
      "Learned from using OpenAI's DALL-E 2 image generation API",
    ],
  },
  {
    company: "Joe's Odd Jobs",
    location: "Ridgewood, NJ",
    startDate: "Oct 2019",
    endDate: "May 2022",
    imgSrc: "https://joesappstorage.blob.core.windows.net/static/app.png",
    title: "Co-founder (Acquired)",
    link: "https://joesoddjobs.com",
    callouts: [
      "Developed job posting and fulfillment software which processed over $100,000",
      "Created React Native mobile application for iOS and Android for contractors",
      "Analyzed tax return data to create a wealth index by zip code to inform pricing strategy",
    ],
  },
  {
    company: "Utilicast",
    location: "Carmel, IN",
    startDate: "Jan 2018",
    endDate: "Aug 2020",
    imgSrc:
      "https://media.licdn.com/dms/image/C560BAQFk3UQ0986mRg/company-logo_100_100/0/1670427755426?e=1683763200&v=beta&t=cBw1yVZJ6cEtuGMiCYBoI8CJMo2vgRGGOvlrzeRLsyI",
    title: "Software Developer",
    link: "https://www.utilicast.com",
    callouts: [
      "Developed and deployed a single page web application using ReactJS, SQL, Azure, and Node.js to manage more than 100 consultants and 50 clients for a $25 million utilities consulting firm",
      "Automated invoice creation and distribution which reduced invoice processing time from three days to less than one minute",
      "Fully integrated a custom software with accounting software to improve project management",
    ],
  },
];
