import {
  CalloutLink,
  FullContainer,
  ImageHeader,
  InnerContainer,
  Navbar,
} from "@/components";
import { Colors, H3, Text, UL } from "@blueprintjs/core";

const Homepage = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingBottom: "8rem" }}>
        <ImageHeader title="About me" />
        <div style={{ maxWidth: 500 }}>
          <Text>
            Hi, my name is Jack. I'm 23, living in New York City, and working on
            building software products. At some point I'd like to found a proper
            startup but currently, I'm working on developing my technical
            skillset, collaborating with friends on projects, and building as
            much as possible.
          </Text>
          <Text style={{ marginTop: 10 }}>
            Quickly -- I prefer building full-stack in Typescript with Node.js,
            React, & SQL with a micro-service, REST-based architecture, deployed
            with Azure.
          </Text>
          <Text style={{ marginTop: 10 }}>
            I consider myself a product engineer more than a
            front-end/back-end/software engineer. I enjoy building user
            experiences and see my technical skills as a means to propagating
            and scaling business ideas. For this reason, I studied Computer
            Science and Finance during undergrad.
          </Text>
          <Text style={{ marginTop: 10 }}>
            In my free time, I enjoy running, golfing, skiing, listening to
            podcasts, traveling, photography, seeing live music, and exploring
            the NYC food scene. Some related things happening this year:
          </Text>
          <UL style={{ paddingLeft: 15 }}>
            <li>Attempting to run 1,000 miles this year (750 last year)</li>
            <li>
              Working on editing a photo book (coffee table book) from my 30-day
              trip to Europe last summer{" "}
            </li>
            <li>Seeing Luke Combs ( & Riley Green ) in May</li>
            <li>Seeing Mt. Joy in August</li>
          </UL>
          <Text style={{ marginTop: 10 }}>
            If you're in NYC, feel free to reach out at{" "}
            <a href="mailto:jackjoliet5@gmail.com">
              <span style={{ color: Colors.BLUE4 }}>jackjoliet5@gmail.com</span>
            </a>
            . I'm constantly looking to meet new people and would love to grab a
            coffee or a beer.
          </Text>
          <Text style={{ marginTop: 10 }}>
            Similarly, if you're working on a software product, I'd love to
            exchange ideas.
          </Text>
          <Text style={{ marginTop: 10 }}>Cheers</Text>
        </div>
        <H3 style={{ marginTop: 50 }}>Links</H3>
        <CalloutLink to="/currently">What I'm working on</CalloutLink>
        <CalloutLink to="/past">Past work</CalloutLink>
      </InnerContainer>
    </FullContainer>
  );
};

export default Homepage;
