import { ResumePoint } from "@/types";
import { useWidth } from "@/utils";
import { Colors, H3, H4, Icon, UL } from "@blueprintjs/core";
import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
  container: {
    width: "100%",
    display: "flex",
  },
  img: {
    width: "2.75rem",
    height: "2.75rem",
    objectFit: "cover",
    borderRadius: 4,
    border: `1px solid ${Colors.GRAY5}`,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  linkIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    height: 16,
    width: 16,
    borderRadius: "50%",
    position: "absolute",
    top: -5,
    right: -5,
    border: `1px solid ${Colors.GRAY5}`,
  },
};

interface Props {
  resumePoint: ResumePoint;
}

const ResumePointPage = ({ resumePoint }: Props) => {
  const { xs } = useWidth();

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: xs ? "column" : "row",
        gap: xs ? 0 : 10,
      }}
    >
      <a
        style={{ position: "relative", width: "fit-content" }}
        href={resumePoint.link || ""}
        target={resumePoint.link ? "_none" : ""}
      >
        {resumePoint.link && (
          <div style={styles.linkIcon}>
            <Icon size={8} icon="link" />
          </div>
        )}
        <img
          alt={`${resumePoint.company}`}
          style={styles.img}
          src={resumePoint.imgSrc}
        />
      </a>
      <div style={styles.column}>
        <div style={{ ...styles.row, height: "3rem" }}>
          <div style={styles.column}>
            <H3 style={{ margin: 0 }}>{resumePoint.company}</H3>
            <H4 style={{ fontWeight: 300, marginBottom: 0 }}>
              {resumePoint.location}
            </H4>
          </div>
          <H4
            style={{ whiteSpace: "nowrap", fontWeight: 300, marginBottom: 0 }}
          >
            <span style={{ fontSize: 16 }}>
              {!resumePoint.endDate && "since "}
            </span>
            {resumePoint.startDate}
            {resumePoint.endDate && " - " + resumePoint.endDate}
          </H4>
        </div>
        <H4 style={{ fontWeight: 300, marginTop: 10 }}>
          <i>{resumePoint.title}</i>
        </H4>
        <UL style={{ paddingLeft: 15, marginTop: 0 }}>
          {resumePoint.callouts.map((callout: string, i) => (
            <li key={i + resumePoint.company} style={{ textAlign: "justify" }}>
              {callout}
            </li>
          ))}
        </UL>
      </div>
    </div>
  );
};

export default ResumePointPage;
