import { HotkeysProvider } from "@blueprintjs/core";

import "./index.css";

import Router from "./router";

import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <HotkeysProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </HotkeysProvider>
  );
};

export default App;
