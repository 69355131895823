import { FullContainer } from "@/components";

import { Button, NonIdealState } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <FullContainer>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <NonIdealState
          icon="path-search"
          title="Looks like you're lost"
          description="Find your way home"
          action={
            <Link to="/">
              <Button outlined icon="home">
                Go home
              </Button>
            </Link>
          }
        />
      </div>
    </FullContainer>
  );
};

export default NotFound;
