import {
  CalloutLink,
  FullContainer,
  Header,
  InnerContainer,
  Navbar,
  ResumePoint,
} from "@/components";
import { H3 } from "@blueprintjs/core";
import { CSSProperties } from "react";
import { resume } from "@/assets/resume";

const styles: Record<string, CSSProperties> = {
  resumePointsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 40,
  },
};

const PastWorkPage = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingBottom: "8rem" }}>
        <Header title="Past work" />
        <div style={styles.resumePointsContainer}>
          {resume
            .filter((r) => r.endDate)
            .map((resumePoint, i) => (
              <ResumePoint
                resumePoint={resumePoint}
                key={`${resumePoint.company}${i}`}
              />
            ))}
        </div>
        <H3 style={{ marginTop: 50 }}>Links</H3>
        <CalloutLink to="/">About me</CalloutLink>
        <CalloutLink to="/currently">What I'm working on</CalloutLink>
      </InnerContainer>
    </FullContainer>
  );
};

export default PastWorkPage;
