import React from "react";
import { Link } from "react-router-dom";

import { EMAIL_URL, LINKEDIN_URL, toaster, useWidth } from "@/utils";

import { FaLinkedin } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Alignment, Navbar, Colors, Intent } from "@blueprintjs/core";
import InnerContainer from "./InnerContainer";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 10000,
    background: "white",
  },
};

const StaticNavbar = () => {
  const { xs, sm } = useWidth();

  return (
    <div style={styles.container}>
      <InnerContainer>
        <Navbar
          style={{
            boxShadow: "none",
            padding: "0.5rem 0",
            height: xs || sm ? "auto" : 60,
          }}
        >
          <Navbar.Group align={Alignment.LEFT}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: Colors.DARK_GRAY1,
                height: "100%",
              }}
            >
              <Navbar.Heading
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                  height: "100%",
                }}
              >
                JACK JOLIET
              </Navbar.Heading>
            </Link>
          </Navbar.Group>
          <Navbar.Group style={{ gap: "0.25rem" }} align={Alignment.RIGHT}>
            <a
              onClick={() => {
                toaster.show({
                  message: EMAIL_URL + " copied to clipboard.",
                  intent: Intent.SUCCESS,
                });
                window.navigator.clipboard.writeText(EMAIL_URL);
              }}
              href={`mailto:${EMAIL_URL}`}
            >
              <IoMdMail size={28} />
            </a>
            <a href={LINKEDIN_URL} target="_none">
              <FaLinkedin size={22} />
            </a>
          </Navbar.Group>
        </Navbar>
      </InnerContainer>
    </div>
  );
};

export default StaticNavbar;
