import { Colors, H4, Icon } from "@blueprintjs/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  children: ReactNode;
}

const CalloutLink = ({ to, children }: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5, padding: "3px 0" }}>
      <Icon size={16} icon="link" />
      <Link to={to}>
        <H4
          style={{
            fontWeight: 300,
            color: Colors.BLUE4,
            textDecoration: "underline",
            margin: 0,
          }}
        >
          {children}
        </H4>
      </Link>
    </div>
  );
};

export default CalloutLink;
