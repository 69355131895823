import { Route, Routes, useLocation } from "react-router-dom";

import { CurrentWorkPage, Homepage, NotFound, PastWorkPage } from "@/scenes";

import { useEffect } from "react";

const Router = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/currently" element={<CurrentWorkPage />} />
      <Route path="/past" element={<PastWorkPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
